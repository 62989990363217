import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"
import PostItem from "components/resource-list"

const StyledContainer = styled(Container)`
  max-width: 1200px; 
  padding: 0;
  margin: 0 auto;

  h3 {
	font-size: 24px;
	text-align: center;
	margin: 0;
	padding: 0;

	${breakpoint.small`
		font-size: 22px;
		margin-left: 40px;
		text-align: left;
	`}
}
  `

const StyledWebinars = styled.section`
	padding: 0;
`

const Webinars = props => {
	const data = useStaticQuery(graphql`
    query {
        allWpWebinar (filter: {slug: {ne: "sti-testing-new-point-of-care-advances"}, webinars: {product: {eq: "Sexual Health Test"}, presentationDate: {ne: "October 5, 2021"}}}, 
		sort: {fields: date, order: DESC}, limit:8) {
        nodes {
          id
          title
		  slug
		  dateGmt
		  modifiedGmt
		webinars {
			existingSlug
			product
			description
			presenter
			presentationDate
			featured
			featuredUrl
            photo {
              localFile {
				publicURL
                childImageSharp {
					gatsbyImageData(width: 600)
                  }
                }
              }
            }
          }
        }
      }
  `)
	return (
		<StyledWebinars>
			<StyledContainer>
				<div className="fullList">
					{data.allWpWebinar.nodes.map((post) => (
						<PostItem
							resourceType="webinar"
							key={post.id}
							link={post.webinars.existingSlug}
							title={post.title}
							presenter={post.webinars.presenter}
							image={
								post.webinars.photo.localFile
									? post.webinars.photo.localFile
									: false
							}
							publicURL={
								post.webinars.photo.localFile.publicURL
									? post.webinars.photo.localFile.publicURL
									: false
							}
							featured={post.webinars.featured}
							featuredUrl={post.webinars.featuredUrl}
							date={post.webinars.presentationDate}
							dateGmt={post.dateGmt}
							modifiedGmt={post.modifiedGmt}
							linkText="View Webinar"
							newWindow="_blank"
							relRef="noopener noreferrer"
						/>
					))}
				</div>
			</StyledContainer>
		</StyledWebinars>
	)
}

export default Webinars